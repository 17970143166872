<template>
<!--  <v-expansion-panel>-->
<!--    <v-expansion-panel-header>Cena</v-expansion-panel-header>-->
<!--    <v-expansion-panel-content>-->
      <v-card-text>
        <div id="price-switcher" class="d-flex">
          <span class="mr-3 align-self-center">Cena za noc</span>
          <v-switch
            dense
            class="mx-0 px-0"
            style="margin-top: 0"
            hide-details
            v-model="priceFilterType"
            color="hotelsOrange"
          >
          </v-switch>
          <span class="ml-0 align-self-center">Za pobyt</span>
        </div>
        <v-range-slider
          hint="Cena za pobyt"
          v-model="priceRange"
          hide-details
          :max="5000"
          :min="0"
          color="hotelsOrange"
        ></v-range-slider>
        <div class="d-flex">
          <v-text-field
            :value="priceRange[0]"
            label="Min Cena"
            outlined
            :max="4999"
            :min="0"
            color="hotelsOrange"
            type="number"
            class="rounded-r-0"
            dense
            @change="$set(priceRange, 0, $event)"
          >
          </v-text-field>
          <v-text-field
            :value="priceRange[1]"
            label="Max Cena"
            outlined
            :max="5000"
            :min="1"
            type="number"
            dense
            class="rounded-l-0"
            color="hotelsOrange"
            @change="$set(priceRange, 1, $event)"
          >
          </v-text-field>
        </div>
      </v-card-text>
<!--    </v-expansion-panel-content>-->
<!--  </v-expansion-panel>-->
</template>

<script>
export default {
  name: 'PriceFilter',
  data () {
    return {
      priceRange: [
        this.$store.state.search.searchParameters.price.min,
        this.$store.state.search.searchParameters.price.max
      ]
    }
  },
  computed: {
    priceFilterType: {
      get () {
        return this.$store.state.search.searchParameters.priceFilterType
      },
      set (val) {
        // false -> za noc
        // true -> za pobyt
        this.$store.state.search.searchParameters.priceFilterType = val
      }
    }
  },
  watch: {
    priceRange: {
      deep: true,
      handler (range) {
        this.$store.state.search.searchParameters.price.min = range[0]
        this.$store.state.search.searchParameters.price.max = range[1]
      }
    }
  }
}
</script>

<style scoped>

</style>
