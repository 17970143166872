<template>
  <v-expansion-panel>
    <v-expansion-panel-header>Typ ubytování</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card-text>
        <v-checkbox
          multiple
          v-for="propertyType in propertyTypes"
          :key="propertyType.id"
          :label="propertyType.name"
          :value="propertyType.id"
          v-model="selectedPropertyTypes"
          dense
          color="hotelsOrange"
          hide-details
        >
        </v-checkbox>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'TypeFilter',
  data () {
    return {
      propertyTypes: []
    }
  },
  computed: {
    selectedPropertyTypes: {
      get () {
        return this.$store.state.search.searchParameters.propertyTypeIds
      },
      set (val) {
        this.$store.state.search.searchParameters.propertyTypeIds = val
      }
    }
  },
  created () {
    this.$http.get('/property-types')
      .then(res => {
        this.propertyTypes = res.data
      })
  }
}
</script>

<style scoped>

</style>
