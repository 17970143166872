<template>
<!--  <v-expansion-panel>-->
<!--    <v-expansion-panel-header>Vybavení</v-expansion-panel-header>-->
<!--    <v-expansion-panel-content>-->
  <div>
    <v-card-title class="pl-0">Vybavení</v-card-title>
    <v-card-text class="d-flex flex-wrap">
      <v-checkbox
        v-for="equipmentType in equipmentTypes"
        :key="equipmentType.id"
        dense
        v-model="equipment"
        hide-details
        :value="equipmentType.id"
        color="hotelsOrange"
        style="min-width: 100px"
        :label="equipmentType.name">
      </v-checkbox>
    </v-card-text>
  </div>
<!--    </v-expansion-panel-content>-->
<!--  </v-expansion-panel>-->
</template>

<script>
export default {
  props: {
    equipmentTypes: {
      required: true,
      type: Array
    }
  },
  name: 'EquipmentFilter',
  computed: {
    equipment: {
      get () {
        return this.$store.state.search.searchParameters.equipment
      },
      set (val) {
        this.$store.state.search.searchParameters.equipment = val
      }
    }
  }
}
</script>

<style scoped>

</style>
