<template>
<!--  <v-expansion-panel>-->
<!--    <v-expansion-panel-header>Hodnocení</v-expansion-panel-header>-->
<!--    <v-expansion-panel-content>-->
<div>
  <v-card-title >Hodnocení ubytování</v-card-title>
  <v-card-text class="mb-2 mt-1 d-flex flex-wrap">
    <v-checkbox
      label="Bez hodnocení"
      dense
      :value="null"
      v-model="rating"
      hide-details
      color="hotelsOrange"
      multiple
    >
    </v-checkbox>
    <v-checkbox
      label="1 Hvězdička"
      dense
      :value="1"
      v-model="rating"
      hide-details
      color="hotelsOrange"
    >
    </v-checkbox>
    <v-checkbox
      label="2 Hvězdičky"
      dense
      :value="2"
      v-model="rating"
      hide-details
      color="hotelsOrange"
    >
    </v-checkbox>
    <v-checkbox
      label="3 Hvězdičky"
      dense
      :value="3"
      v-model="rating"
      hide-details
      color="hotelsOrange"
    >
    </v-checkbox>
    <v-checkbox
      label="4 Hvězdičky"
      dense
      :value="4"
      v-model="rating"
      hide-details
      color="hotelsOrange"
    >
    </v-checkbox>
    <v-checkbox
      label="5 Hvězdiček"
      dense
      :value="5"
      v-model="rating"
      hide-details
      color="hotelsOrange"
    >
    </v-checkbox>
  </v-card-text>
</div>
<!--    </v-expansion-panel-content>-->
<!--  </v-expansion-panel>-->
</template>

<script>
export default {
  name: 'RatingFilter',
  computed: {
    rating: {
      get () {
        return this.$store.state.search.searchParameters.rating
      },
      set (val) {
        this.$store.state.search.searchParameters.rating = val
      }
    }
  }
}
</script>

<style scoped>

</style>
