<template>
  <div>
<!--    <v-expansion-panel-header class="pb-0 pt-0">Ostatní</v-expansion-panel-header>-->
<!--    <v-expansion-panel-content>-->
    <v-card-title >Ostatní</v-card-title>
    <v-card-text>
        <div
          v-for="ac in activityTypes"
          :key="ac.id"
          class="other-filter-group"
        >
          <span class="d-flex justify-lg-space-between">{{ac.name}} (m)
            <v-checkbox
              class="pa-0 ma-0"
              v-model="includedLocations"
              :value="ac.id"
              color="hotelsOrange"
            />
          </span>
          <v-range-slider
            v-if="includedLocations[ac.id]"
            :value="[location[ac.id].min, location[ac.id].max]"
            @input="updateRangeValue(ac.id, $event)"
            hide-details
            :max="5000"
            :min="0"
            step="10"
            color="hotelsOrange"
          ></v-range-slider>
          <div v-if="includedLocations.includes(ac.id)" class="d-flex">
            <v-text-field
              :value="location[ac.id].min"
              label="Min vzdálenost"
              outlined
              type="number"
              color="hotelsOrange"
              dense
              :max="5000"
              :min="0"
              @change="$set(location[ac.id], 'min', +$event); $forceUpdate()"
            >
            </v-text-field>
            <v-text-field
              :value="location[ac.id].max"
              label="Max vzdálenost"
              outlined
              color="hotelsOrange"
              type="number"
              dense
              :max="5000"
              :min="0"
              @change="$set(location[ac.id], 'max', +$event); $forceUpdate()"
            >
            </v-text-field>
          </div>
        </div>
        <div class="other-filter-group">
          <span>Stravování</span>
          <v-select
            multiple
            :items="boardingTypes"
            dense
            hide-selected
            color="hotelsOrange"
            item-text="name"
            item-value="id"
            v-model="boarding"
            outlined
          >
          </v-select>
          <span>Půjčení vybavení</span>
            <v-select
              multiple
              outlined
              :items="rentalTypes"
              dense
              v-model="rental"
              item-text="name"
              item-value="id"
              color="hotelsOrange"
            >
            </v-select>
        </div>
      </v-card-text>
<!--    </v-expansion-panel-content>-->
  </div>
</template>

<script>
export default {
  props: {
    rentalTypes: {
      type: Array,
      required: true
    },
    boardingTypes: {
      type: Array,
      required: true
    },
    activityTypes: {
      required: true,
      type: Array
    }
  },
  name: 'OtherFilter',
  data () {
    return {
      minPrice: 0,
      maxPrice: 5000,
      priceRange: [0, 5000],
      includedLocations: []
    }
  },
  methods: {
    updateRangeValue (id, event) {
      this.$set(this.location[id], 'min', event[0])
      this.$set(this.location[id], 'max', event[1])
      this.$forceUpdate()
    }
  },
  computed: {
    boarding: {
      get () {
        return this.$store.state.search.searchParameters.equipment
      },
      set (val) {
        const otherEquipment = this.$store.state.search.searchParameters.equipment
          .filter(eq => {
            const boarderTypeId = this.boarding.map(boardingType => boardingType.id)
            return !boarderTypeId.includes(eq.id)
          })
        // [...new Set([...this.$store.state.search.searchParameters.equipment, ...val])]
        this.$store.state.search.searchParameters.equipment = [...otherEquipment, ...val]
        // console.log(this.$store.state.search.searchParameters.equipment)
      }
    },
    rental: {
      get () {
        return this.$store.state.search.searchParameters.equipment
      },
      set (val) {
        const otherEquipment = this.$store.state.search.searchParameters.equipment
          .filter(eq => {
            const rentalTypeIds = this.rentalTypes.map(r => r.id)
            return !rentalTypeIds.includes(eq.id)
          })
        this.$store.state.search.searchParameters.equipment = [...new Set([...otherEquipment, ...val])]
      }
    },
    location: {
      get () {
        return this.$store.state.search.searchParameters.location
      },
      set (val) {
        this.$store.state.search.searchParameters.location = val
      }
    }
  },
  created () {
    /* this.activityTypes.forEach(activity => {
      this.includedLocations[activity.id] = false
    }) */
  },
  watch: {
    includedLocations: {
      deep: true,
      handler (val) {
        this.location = {}
        val.forEach(activityId => {
          this.$set(this.location, activityId, {
            min: 0,
            max: 5000
          })
          /* this.location[activityId] = {
              min: 0,
              max: 5000
            } */
        })
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style scoped>

</style>
