<template>
<!--  <v-expansion-panel>-->
<!--    <v-expansion-panel-header class="pb-0 pt-0">Vlastnosti</v-expansion-panel-header>-->
<!--    <v-expansion-panel-content>-->
      <v-card-text>
        <v-card-title class="pl-0">Vlastnosti ubytování</v-card-title>
        <v-row class="d-flex">
          <v-col cols="6" class="align-self-center">
            <span class="mr-4">Počet ložnic</span>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label=""
              v-model.number="bedrooms"
              dense
              single-line
              hide-details
              class="rounded-0"
              outlined
              type="number"
              style="max-width: 40%; min-width: 75px"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="6" class="align-self-center">
            <span class="mr-4">Počet lůžek</span>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label=""
              dense
              single-line
              hide-details
              class="rounded-0"
              v-model.number="beds"
              outlined
              type="number"
              style="max-width: 40%; min-width: 75px"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-checkbox
          dense
          hide-details
          label="Vlastní kuchyň"
          :value="4"
          multiple
          v-model="equipment"
          color="hotelsOrange"
        >
        </v-checkbox>
        <v-checkbox
          dense
          hide-details
          label="Vlastní koupelna"
          :value="2"
          multiple
          v-model="equipment"
          color="hotelsOrange"
        >
        </v-checkbox>
      </v-card-text>
<!--    </v-expansion-panel-content>-->
<!--  </v-expansion-panel>-->
</template>

<script>
export default {
  name: 'AttributeFilter',
  computed: {
    beds: {
      get () {
        return this.$store.state.search.searchParameters.beds
      },
      set (val) {
        this.$store.state.search.searchParameters.beds = val
      }
    },
    bedrooms: {
      get () {
        return this.$store.state.search.searchParameters.bedrooms
      },
      set (val) {
        this.$store.state.search.searchParameters.bedrooms = val
      }
    },
    equipment: {
      get () {
        return this.$store.state.search.searchParameters.equipment
      },
      set (val) {
        this.$store.state.search.searchParameters.equipment = val
      }
    }
  }
}
</script>

<style scoped>

</style>
