<template>
  <v-col xs="12" sm="6" md="6" lg="4" xl="3">
    <v-card
      class="mx-auto card-rounded"
      elevation="5"
      @click="redirectToDetail"
    >
      <v-img
        v-if="property.miniPhotoPath"
        :src="mainPhotoUrl"
        height="200px"
      ></v-img>
      <v-icon
        v-else
        size="50"
      >
        mdi-camera-outline
      </v-icon>
      <v-card-text>
        <div v-if="property">
          <v-row class="rating-price">
            <v-col class="card-rating col-sm-5 col-3">
              <span class="property-reviews d-flex text-center" v-if="property.rating !== null">
                <span class="grey--text align-self-center  text-caption ml-2">({{ property.rating }})</span>
                <v-rating
                  :value="property.rating"
                  color="orange"
                  half-increments
                  class="align-self-center"
                  :length="property.rating"
                  small
                  dense
                >
                </v-rating>
              </span>
              <template class="text-sm-caption text--secondary" v-else>
                bez hodnocení
              </template>
            </v-col>
            <v-col xs="1" sm="2" class="object-price-triangle"></v-col>
            <v-col xs="6" sm="5" style="padding-left: 0; padding-bottom: 0">
              <div class="object-price py-2">Od {{property.totalPrice}} Kč</div>
            </v-col>

          </v-row>
          <v-card-title class="pt-3">
            <span class="property-title">{{ property.name }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="my-4 mx-3">{{ property.descriptionPreview }}</v-card-text>
          <v-divider></v-divider>
          <v-row class="align-center" no-gutters>
            <v-col cols="9">
              <v-card-subtitle>
                <div class="text-truncate">
                  <v-icon color="green" small style="vertical-align: text-top">mdi-map-marker</v-icon>
                  {{ property.localityDisplayName }}
                </div>
              </v-card-subtitle>
            </v-col>
            <v-col class="text-right" cols="3">
<!--              <v-card-actions class="justify-end">-->
<!--                <v-btn-->
<!--                  @click="redirectToDetail"-->
<!--                  color="primary">-->
<!--                  Detail-->
<!--                  <v-icon>mdi-arrow-right</v-icon>-->
<!--                </v-btn>-->
<!--              </v-card-actions>-->
            </v-col>
          </v-row>
        </div>
        <v-skeleton-loader v-else type="card"/>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CardMiniatureHorizontalSearch',
  props: {
    /**
     * {
     *   id: Number,
     *   miniPhotoPath: String,
     *   name: String,
     *   rating: Number,
     *   descriptionPreview: String,
     *   localityDisplayName: String,
     *   totalPrice: Number
     * }
     */
    property: {
      required: true,
      type: Object
    }
  },
  computed: {
    mainPhotoUrl () {
      return process.env.VUE_APP_BACKEND_PUBLIC_URL + this.property.miniPhotoPath
    }
  },
  methods: {
    redirectToDetail () {
      // dateFrom=2021-08-20&dateTo=2021-08-21&adults=1&children=1
      this.$router.push({
        name: 'property-detail',
        params: { id: this.property.id },
        query: {
          dateFrom: this.$route.query.dateFrom,
          dateTo: this.$route.query.dateTo,
          adults: this.$route.query.adults,
          children: this.$route.query.children
        }
      })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  }
}
</script>

<style scoped type="text/css">
.card-rating{
  display: flex;
  align-content: baseline;
  padding-bottom: 0;
}
.card-rounded{
  border-radius: 15px !important;
}
.property-title{
  font-family: 'Poppins', sans-serif;
  color: black;
}
.property-reviews {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}
.object-price{
  background-color: var(--v-hotelsBlue-base);
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.object-price-triangle{
  background: linear-gradient(45deg, #ffffff 50%, var(--v-hotelsBlue-base) 50%);}
.v-card__text {
  padding: 0 !important;
}
</style>
