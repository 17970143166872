<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-end">
        <v-btn
          v-if="!filterVisible && responsiveFilter > 600"
          @click="filterVisible = true"
          color="hotelsOrange"
          class="text-lowercase py-7 text-right filter-button"
          dark
          id="filterButton">
          více možností
        </v-btn>
        <v-btn
          v-if="!filterVisible && responsiveFilter <= 600"
          @click="dialog = true"
          color="hotelsOrange"
          class="text-lowercase py-7 text-right filter-button"
          dark>
          více možností
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="dialog">
      <div v-if="responsiveFilter < 600">
        <v-btn
          class="ma-2"
          @click="dialog = true"
        >
          Filtry
          <v-icon>
            mdi-tune-vertical-variant
          </v-icon>
        </v-btn>
        <v-dialog
          v-model="dialog"
          fullscreen
          :activator="responsiveFilter < 600"
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
        >
          <v-card>
            <v-card-title class="pt-1 pb-0">Hledat podle:</v-card-title>
            <v-expansion-panels
              accordion
              flat
              multiple
              v-model="panels"
              id="filer-panels"
            >
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <TypeFilter></TypeFilter>
                    <EquipmentFilter :equipment-types="equipmentTypes.filter(eq => eq.equipmentGroup === 1)"></EquipmentFilter>
                    <OtherFilter
                      :boarding-types="equipmentTypes.filter(eq => eq.equipmentGroup === 3)"
                      :rental-types="equipmentTypes.filter(eq => eq.equipmentGroup === 5)"
                      :activity-types="equipmentTypes.filter(eq => eq.equipmentGroup === 2)"
                      v-if="equipmentTypes.length"
                    ></OtherFilter>
                  </v-col>
                  <v-col cols="6">
                    <PriceFilter></PriceFilter>
                    <AttributeFilter></AttributeFilter>
                    <RatingFilter></RatingFilter>
                    <v-btn
                      @click="dialog = false"
                      color="hotelsOrange"
                      class="text-lowercase py-7 text-right float-end"
                      id="closeButton"
                      dark>
                      Zavřít filtr
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panels>
          </v-card>
          <!--          <v-card>-->
          <!--            <v-card-title class="pt-1 pb-0 justify-space-between" style="width: 100%">-->
          <!--              Hledat podle:-->
          <!--              <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>-->
          <!--            </v-card-title>-->
          <!--            <v-expansion-panels-->
          <!--              accordion-->
          <!--              flat-->
          <!--              multiple-->
          <!--              v-model="panels"-->
          <!--              id="filer-panels"-->
          <!--            >-->
          <!--              <TypeFilter></TypeFilter>-->
          <!--              <PriceFilter></PriceFilter>-->
          <!--              <EquipmentFilter :equipment-types="equipmentTypes.filter(eq => eq.equipmentType.id === 1)"></EquipmentFilter>-->
          <!--              <AttributeFilter></AttributeFilter>-->
          <!--              <RatingFilter></RatingFilter>-->
          <!--              <OtherFilter></OtherFilter>-->
          <!--            </v-expansion-panels>-->
          <!--          </v-card>-->
        </v-dialog>
      </div>
    </div>
    <div v-if="filterVisible">
      <v-card>
        <v-card-title class="pt-1 pb-0">Hledat podle:</v-card-title>
        <v-expansion-panels
          accordion
          flat
          multiple
          v-model="panels"
          id="filer-panels"
        >
          <v-container>
            <v-row>
              <v-col cols="6">
                <TypeFilter></TypeFilter>
                <EquipmentFilter :equipment-types="equipmentTypes.filter(eq => eq.equipmentGroup === 1)"></EquipmentFilter>
                <OtherFilter
                  :boarding-types="equipmentTypes.filter(eq => eq.equipmentGroup === 3)"
                  :rental-types="equipmentTypes.filter(eq => eq.equipmentGroup === 5)"
                  :activity-types="equipmentTypes.filter(eq => eq.equipmentGroup === 2)"
                  v-if="equipmentTypes.length"
                ></OtherFilter>
              </v-col>
              <v-col cols="6">
                <PriceFilter></PriceFilter>
                <AttributeFilter></AttributeFilter>
                <RatingFilter></RatingFilter>
                <v-btn
                  @click="filterVisible = false"
                  color="hotelsOrange"
                  class="text-lowercase py-7 text-right float-end filter-button"
                  dark>
                  Zavřít filtr
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panels>
      </v-card>
    </div>
  </div>

</template>

<script>
import PriceFilter from './extendedSearchComponents/PriceFilter'
import TypeFilter from './extendedSearchComponents/TypeFilter'
import AttributeFilter from './extendedSearchComponents/AttributeFilter'
import EquipmentFilter from './extendedSearchComponents/EquipmentFilter'
import OtherFilter from './extendedSearchComponents/OtherFilter'
import RatingFilter from '@/components/search/extendedSearchComponents/RatingFilter'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ExtendedSearchForm',
  components: { RatingFilter, OtherFilter, EquipmentFilter, AttributeFilter, TypeFilter, PriceFilter },
  data () {
    return {
      dialog: false,
      panels: [],
      equipmentTypes: [],
      filterVisible: false
    }
  },
  methods: {
    ...mapActions({
      search: 'search/search'
    })
  },
  computed: {
    responsiveFilter () {
      return screen.availWidth
    },
    ...mapGetters({
      searchParameters: 'search/searchParameters'
    })
  },
  created () {
    this.$http.get('/equipment-types')
      .then(res => {
        this.equipmentTypes = res.data
      })
  }
}
</script>

<style scoped>
#filer-panels{
  overflow: auto;
}
>>>.v-expansion-panel-content__wrap, >>>.v-card__text{
  padding-bottom: 0;
  padding-top: 0;
}
.filter-button{
  font-family: 'Readex Pro', sans-serif;
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
}
</style>
