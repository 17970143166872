<template>
  <div>
    <div id="search-result-bg"></div>
    <v-container id="search-form">
      <v-row >
        <h1 id="color-white">Vaše dovolená 2022</h1>
        <v-col cols="12">
          <SimpleSearchForm
            @search-init="resetSearch"
          ></SimpleSearchForm>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
<!--      <v-row>-->
<!--        <v-col cols="12" class="text-end">-->
<!--          <v-btn-->
<!--            v-if="!filterVisible"-->
<!--            @click="filterVisible = !filterVisible"-->
<!--            color="hotelsOrange"-->
<!--            class="text-lowercase py-7 text-right"-->
<!--            dark-->
<!--            id="filterButton">-->
<!--            více možností-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <div v-if="filterVisible">-->
        <ExtendedSearchForm @closeFilter="filterVisible = !filterVisible"></ExtendedSearchForm>
<!--      </div>-->
      <v-row class="mt-7">
        <v-col cols="12" v-if="fetchingResults">
          <v-skeleton-loader type="card@3" ></v-skeleton-loader>
        </v-col>
        <v-container id="results" v-else>
          <v-row>
            <div v-if="results.length === 0">
              Žádné nabídky k zobrazení
            </div>
            <CardMiniatureSearch
              v-else
              v-for="property in results"
              :key="property.id"
              :property="property"
            ></CardMiniatureSearch>
          </v-row>
        </v-container>
      </v-row>
      <v-row class="pb-5">
        <v-spacer />
        <v-btn
          v-if="!fetchingResults"
          color="hotelsBlue"
          dark
        >
          Načíst další
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SimpleSearchForm from '../../components/search/SimpleSearchForm'
import ExtendedSearchForm from '../../components/search/ExtendedSearchForm'
import CardMiniatureSearch from '@/components/property/CardMiniatureSearch'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { EventBus } from '@/events/event_bus'

export default {
  name: 'SearchResultPage',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    CardMiniatureSearch,
    ExtendedSearchForm,
    SimpleSearchForm
  },
  data () {
    // return {
    //   filterVisible: false
    // }
  },
  computed: {
    responsiveFilter () {
      return screen.availWidth
    },
    ...mapGetters({
      results: 'search/results',
      fetchingResults: 'search/fetchingResults',
      searchParameters: 'search/searchParameters'
    })
  },
  methods: {
    resetSearch (parameters) {
      this.$router.replace({
        name: 'search-result',
        query: parameters
      })
    },
    loadFilteredProperties () {},
    ...mapMutations({
      setSearchParameters: 'search/setSearchParameters'
    }),
    ...mapActions({
      search: 'search/search'
    })
  },
  watch: {
    searchParameters: {
      deep: true,
      handler () {
        this.loadFilteredProperties()
      }
    }
  },
  created () {
    this.$store.state.search.fetchingResults = true
    this.loadFilteredProperties = this.debounce(this.search, 2000)
    // console.log('w', this.$route.query)
    const searchParams = {
      ...this.$route.query,
      adults: +this.$route.query.adults,
      children: +this.$route.query.children
    }
    searchParams.locality = {
      displayName: this.$route.query.locality
    }
    this.setSearchParameters(searchParams)

    EventBus.$on('objects-loaded', () => {
      // console.log(this.searchParameters.locality)
      this.search()
    })
  }
}
</script>

<style scoped type="text/css">
#search-result-bg{
  background-image: url("../../assets/search-bg.png");
  background-position: 50% 75%;
  background-size: cover;
  height: 22vh;
}
#search-form{
  margin-top: -13vh;
  padding-bottom: 0;
}
#color-white{
  color: white;
}
#filterButton{
  font-family: 'Readex Pro', sans-serif;
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;

}
</style>
